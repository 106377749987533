import React from 'react';

const Wish = ({ name }) => {
  return (
    <div className='wish-message'>
      HAPPY BIRTHDAY <span className='highlight'>{name.toUpperCase()}</span> !!! <br /> <br /> 
      Semoga diulang tahun ke-22 <span className='highlight'>{name.toUpperCase()}</span> <br /> dilimpahkan kasih sayang dan kebahagian

      <br /> 
      <br /> 
      <br /> 
      <br /> 
      <br /> 

      With Love,
      <br />
      Aziz Anwar
    </div>
  );
};

export default Wish;
